import { HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';

export class General {
}

// let credential = false

export let baseUrl = environment.apiURL;
export let loginUrl = environment.apiLoginURL;
export const httpOptions = {
    headers: new HttpHeaders({
      'APPSecret': 'fLP3Dgvf7habt1qQMXHfFOWaHVXyNw7zEmbv0QMpURu',
      // Accept: "application/json",
      // "Content-Type": "application/json",
      'APPKey': 'a96834df-0821-4956-a0f2-2df3c23bde25',
    }),
  };

  export const httpAuthOptions = {
    headers: new HttpHeaders({
      'channel': '1',
    }),
  };